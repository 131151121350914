import { createAsyncThunk } from "@reduxjs/toolkit";
import mediaApi from 'api/mediaApi';
import inventoryApi from 'api/inventoryApi';
import { formatQueryParams } from "helpers/format";
import agencyApi from "api/agencyApi";
import revenueApi from "api/revenueApi";
import commissionApi from "api/commissionApi";
import settlementApi from "api/settlementApi";
import coupangApi from "api/coupangApi";
import advertiserApi from "api/advertiserApi";
import advertisingApi from "api/advertisingApi";

export const reportMedias = createAsyncThunk("Report Medias", async (params: any = {}) => {
  try {
    const response = await mediaApi.mediaReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportMediasV2 = createAsyncThunk("Report Medias V2", async (params: any = {}) => {
  try {
    const response = await mediaApi.mediaReportsV2(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportMediasSales = createAsyncThunk("Report Medias Sales", async (params: any = {}) => {
  try {
    const response = await mediaApi.mediaReportsSales(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportDaily = createAsyncThunk("Report Daily", async (params: any = {}) => {
  try {
    const response = await mediaApi.dailyReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportHourly = createAsyncThunk("Report Hourly", async (params: any = {}) => {
  try {
    const response = await mediaApi.hourlyReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportMediaHourly = createAsyncThunk("Report Media Hourly", async (params: any = {}) => {
  try {
    const response = await mediaApi.mediaHourlyReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportClick = createAsyncThunk("Report CLick", async (params: any = {}) => {
  try {
    const response = await mediaApi.clickReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const reportInventories = createAsyncThunk("Report Inventories", async (params: any = {}) => {
  try {
    const response = await inventoryApi.inventoryReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportAgencies = createAsyncThunk("Report Agencies", async (params: any = {}) => {
  try {
    const response = await agencyApi.agencyReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const reportRevenues = createAsyncThunk("Report Revenues", async (params: any = {}) => {
  try {
    const response = await revenueApi.revenueReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportCommissions = createAsyncThunk("Report Commissions", async (params: any = {}) => {
  try {
    const response = await commissionApi.commissionReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportSettlements = createAsyncThunk("Report Settlement", async (params: any = {}) => {
  try {
    const response = await settlementApi.settlementReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportADPlatforms = createAsyncThunk("Report AD Platform", async (params: any = {}) => {
  try {
    const response = await inventoryApi.ADPlatformReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportADPlatformMedias = createAsyncThunk("Report AD Platform Media", async (params: any = {}) => {
  try {
    const response = await inventoryApi.ADPlatformMediaReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportADTypes = createAsyncThunk("Report AD Type", async (params: any = {}) => {
  try {
    const response = await inventoryApi.ADTypeReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportCoupangs = createAsyncThunk("Report Coupang", async (params: any = {}) => {
  try {
    const response = await coupangApi.coupangReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const reportAdvertisers = createAsyncThunk("Report Advertiser", async (params: any = {}) => {
  try {
    const response = await advertiserApi.advertiserReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const reportAdvertiserAgencies = createAsyncThunk("Report Advertiser Agencies", async (params: any = {}) => {
  try {
    const response = await advertiserApi.advertiserAgencyReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const reportAdvertising = createAsyncThunk("Report Advertising", async (params: any = {}) => {
  try {
    const response = await advertisingApi.advertisingReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const reportCoupangNasmedias = createAsyncThunk("Coupang Nasmedia", async (params: any = {}) => {
  try {
    const response = await coupangApi.coupangNasmediaReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const refreshCoupangReports = async (params: any = {}) => {
  try {
    const response = await coupangApi.refreshCoupangReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const downloadExcelSettlementReports = async (params: any = {}) => {
  try {
    const response = await settlementApi.downloadExcelSettlementReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

