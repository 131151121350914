import { createAsyncThunk } from "@reduxjs/toolkit";
import mediaApi from 'api/mediaApi';
import inventoryApi from 'api/inventoryApi';
import { formatQueryParams } from "helpers/format";
import credentialApi from "api/credentialApi";
import agencyApi from "api/agencyApi";
import advertiserApi from "api/advertiserApi";
import advertisingApi from "api/advertisingApi";
import adPlatformApi from "api/adPlatformApi";
import advertiserAgencyApi from "api/advertiserAgencyApi";

export const getMedias = createAsyncThunk("Medias", async (params: any = {}) => {
  try {
    const response = await mediaApi.medias(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getHistoryConfigCPM = createAsyncThunk("History Config CPM", async (params: any = {}) => {
  try {
    const response = await inventoryApi.getHistoryConfigCPM(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getInventories = createAsyncThunk("Inventories", async (params: any = {}) => {
  try {
    const response = await inventoryApi.inventories(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getInventoryHistories = createAsyncThunk("Inventories Histories", async (params: any = {}) => {
  try {
    const paramsClone = structuredClone(params);
    delete paramsClone.inventory_id;
    const response = await inventoryApi.histories(params?.inventory_id, formatQueryParams(paramsClone))
    return response;
  } catch (error) {
    return error;
  }
});

export const getCredentials = createAsyncThunk("Credentials", async (params: any = {}) => {
  try {
    const response = await credentialApi.credentials(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAgencies = createAsyncThunk("Agencies", async (params: any = {}) => {
  try {
    const response = await agencyApi.agencies(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAdvertiserAgencies = createAsyncThunk("Advertiser Agencies", async (params: any = {}) => {
  try {
    const response = await advertiserAgencyApi.advertiserAgencies(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAdvertisers = createAsyncThunk("Advertisers", async (params: any = {}) => {
  try {
    const response = await advertiserApi.advertisers(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getHistoryConfigAdvertiserCPC = createAsyncThunk("History Config CPC", async (params: any = {}) => {
  try {
    const response = await advertiserApi.getHistoryConfigAdvertiserCPC(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAdvertiserHistories = createAsyncThunk("Advertisers Histories", async (params: any = {}) => {
  try {
    const paramsClone = structuredClone(params);
    delete paramsClone.advertiser_id;
    const response = await advertiserApi.histories(params?.advertiser_id, formatQueryParams(paramsClone))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAdvertising = createAsyncThunk("Advertising", async (params: any = {}) => {
  try {
    const response = await advertisingApi.advertising(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const getADPlatforms = createAsyncThunk("ADPlatforms", async (params: any = {}) => {
  try {
    const response = await adPlatformApi.ADPlatforms(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

// =========== Begin: Media ===========
export const getMedia = async (id: number) => {
  try {
    const response = await mediaApi.getMedia(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadLogoMedia = async (files: any = null) => {
  try {
    const response = await mediaApi.uploadLogoMedia(files)
    return response;
  } catch (error) {
    return error;
  }
};

export const postMedia = async (data: any = {}) => {
  try {
    const response = await mediaApi.postMedia(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putMedia = async (id: number, data: any = {}) => {
  try {
    const response = await mediaApi.putMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteMedia = async (id: number, data: any = {}) => {
  try {
    const response = await mediaApi.deleteMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putAccountMedia = async (id: number, data: any = {}) => {
  try {
    const response = await mediaApi.putAccountMedia(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllMedias = async (params: any = {}) => {
  try {
    const response = await mediaApi.allMedias(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getGenerateApiKeyMedia = async (id: number, params: any = {}) => {
  try {
    const response = await mediaApi.getGenerateApiKeyMedia(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

// =========== End: Media ===========

// =========== Begin: Inventory ===========
export const getAllInventories = async (params: any = {}) => {
  try {
    const response = await inventoryApi.allInventories(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getInventoryByNormal = async (params: any = {}) => {
  try {
    const response = await inventoryApi.inventories(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getInventory = async (id: number) => {
  try {
    const response = await inventoryApi.getInventory(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postInventory = async (data: any = {}) => {
  try {
    const response = await inventoryApi.postInventory(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putInventory = async (id: number, data: any = {}) => {
  try {
    const response = await inventoryApi.putInventory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteInventory = async (id: number, data: any = {}) => {
  try {
    const response = await inventoryApi.deleteInventory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateWebsiteInventory = async (id: number, data: any = {}) => {
  try {
    const response = await inventoryApi.putWebsiteInventory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateMemoInventory = async (id: number, data: any = {}) => {
  try {
    const response = await inventoryApi.putMemoInventory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateConfigInventory = async (id: number, data: any = {}) => {
  try {
    const response = await inventoryApi.putConfigInventory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const postAssignAutomaticRedirect = async (data: any = {}) => {
  try {
    const response = await inventoryApi.postAssignAutomaticRedirect(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const getInventoriesAssign = async (params: any = {}) => {
  try {
    const response = await inventoryApi.getInventoriesAssign(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getConfigurationAssign = async (params: any = {}) => {
  try {
    const response = await inventoryApi.getConfigurationAssign(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postUploadImageCoverInventory = async (data: any = {}) => {
  try {
    const response = await inventoryApi.postUploadImageCoverInventory(data)
    return response;
  } catch (error) {
    return error;
  }
};


// =========== End: Inventory ===========

export const uploadImage = async (data: any = {}) => {
  try {
    const response = await inventoryApi.uploadImage(data);
    return response;
  } catch (error) {
    return error;
  }
};

// =========== Begin: Config CPM ===========
export const getConfigCPMs = async (params: any = {}) => {
  try {
    const response = await inventoryApi.getConfigCPMs(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const addConfigCPM = async (data: any = {}) => {
  try {
    const response = await inventoryApi.postConfigCPM(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const updateConfigCPM = async (id: number, data: any = {}) => {
  try {
    const response = await inventoryApi.putConfigCPM(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteConfigCPM = async (id: number, data: any = {}) => {
  try {
    const response = await inventoryApi.deleteConfigCPM(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
// =========== End: Config CPM ===========

// =========== Begin: Credential ===========

export const getCredential = async (id: number) => {
  try {
    const response = await credentialApi.getCredential(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postCredential = async (data: any = {}) => {
  try {
    const response = await credentialApi.postCredential(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putCredential = async (id: number, data: any = {}) => {
  try {
    const response = await credentialApi.putCredential(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCredential = async (id: number, data: any = {}) => {
  try {
    const response = await credentialApi.deleteCredential(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllCredentials = async (params: any = {}) => {
  try {
    const response = await credentialApi.allCredentials(params)
    return response;
  } catch (error) {
    return error;
  }
};

// =========== End: Credential ===========


// =========== Begin: Agency ===========
export const getAgency = async (id: number) => {
  try {
    const response = await agencyApi.getAgency(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postAgency = async (data: any = {}) => {
  try {
    const response = await agencyApi.postAgency(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putAgency = async (id: number, data: any = {}) => {
  try {
    const response = await agencyApi.putAgency(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAgency = async (id: number, data: any = {}) => {
  try {
    const response = await agencyApi.deleteAgency(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putAccountAgency = async (id: number, data: any = {}) => {
  try {
    const response = await agencyApi.putAccountAgency(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllAgencies = async (params: any = {}) => {
  try {
    const response = await agencyApi.allAgencies(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getGenerateApiKeyAgency = async (id: number, params: any = {}) => {
  try {
    const response = await agencyApi.getGenerateApiKeyAgency(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

// =========== End: Agency ===========


// =========== Begin: Advertiser Agency ===========
export const getAdvertiserAgency = async (id: number) => {
  try {
    const response = await advertiserAgencyApi.getAdvertiserAgency(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postAdvertiserAgency = async (data: any = {}) => {
  try {
    const response = await advertiserAgencyApi.postAdvertiserAgency(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putAdvertiserAgency = async (id: number, data: any = {}) => {
  try {
    const response = await advertiserAgencyApi.putAdvertiserAgency(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAdvertiserAgency = async (id: number, data: any = {}) => {
  try {
    const response = await advertiserAgencyApi.deleteAdvertiserAgency(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putAccountAdvertiserAgency = async (id: number, data: any = {}) => {
  try {
    const response = await advertiserAgencyApi.putAccountAdvertiserAgency(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllAdvertiserAgencies = async (params: any = {}) => {
  try {
    const response = await advertiserAgencyApi.allAdvertiserAgencies(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getGenerateApiKeyAdvertiserAgency = async (id: number, params: any = {}) => {
  try {
    const response = await advertiserAgencyApi.getGenerateApiKeyAdvertiserAgency(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

// =========== End: Advertiser Agency ===========


// =========== Begin: Advertiser ===========
export const getAdvertiser = async (id: number) => {
  try {
    const response = await advertiserApi.getAdvertiser(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postAdvertiser = async (data: any = {}) => {
  try {
    const response = await advertiserApi.postAdvertiser(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putAdvertiser = async (id: number, data: any = {}) => {
  try {
    const response = await advertiserApi.putAdvertiser(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAdvertiser = async (id: number, data: any = {}) => {
  try {
    const response = await advertiserApi.deleteAdvertiser(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putAccountAdvertiser = async (id: number, data: any = {}) => {
  try {
    const response = await advertiserApi.putAccountAdvertiser(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllAdvertisers = async (params: any = {}) => {
  try {
    const response = await advertiserApi.allAdvertisers(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getGenerateApiKeyAdvertiser = async (id: number, params: any = {}) => {
  try {
    const response = await advertiserApi.getGenerateApiKeyAdvertiser(id, params)
    return response;
  } catch (error) {
    return error;
  }
};
// =========== End: Advertiser ===========

// =========== Begin: ConfigAdvertiser CPC ===========
export const getConfigAdvertiserCPCs = async (params: any = {}) => {
  try {
    const response = await advertiserApi.getConfigAdvertiserCPCs(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const addConfigAdvertiserCPC = async (data: any = {}) => {
  try {
    const response = await advertiserApi.postConfigAdvertiserCPC(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const updateConfigAdvertiserCPC = async (id: number, data: any = {}) => {
  try {
    const response = await advertiserApi.putConfigAdvertiserCPC(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteConfigAdvertiserCPC = async (id: number, data: any = {}) => {
  try {
    const response = await advertiserApi.deleteConfigAdvertiserCPC(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
// =========== End: ConfigAdvertiser CPC ===========



// =========== Begin: Advertising ===========
export const getDetailAdvertising = async (id: number) => {
  try {
    const response = await advertisingApi.getAdvertising(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postAdvertising = async (data: any = {}) => {
  try {
    const response = await advertisingApi.postAdvertising(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putAdvertising = async (id: number, data: any = {}) => {
  try {
    const response = await advertisingApi.putAdvertising(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAdvertising = async (id: number, data: any = {}) => {
  try {
    const response = await advertisingApi.deleteAdvertising(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteImageOfAdvertising = async (id_advertising: number, id_image: number, data: any = {}) => {
  try {
    const response = await advertisingApi.deleteImageOfAdvertising(id_advertising, id_image, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllAdvertising = async (params: any = {}) => {
  try {
    const response = await advertisingApi.allAdvertising(params)
    return response;
  } catch (error) {
    return error;
  }
};
// =========== End: Advertising ===========

// =========== Begin: ADPlatform ===========
export const getADPlatform = async (id: number) => {
  try {
    const response = await adPlatformApi.getADPlatform(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postADPlatform = async (data: any = {}) => {
  try {
    const response = await adPlatformApi.postADPlatform(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putADPlatform = async (id: number, data: any = {}) => {
  try {
    const response = await adPlatformApi.putADPlatform(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteADPlatform = async (id: number, data: any = {}) => {
  try {
    const response = await adPlatformApi.deleteADPlatform(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllADPlatforms = async (params: any = {}) => {
  try {
    const response = await adPlatformApi.allADPlatforms(params)
    return response;
  } catch (error) {
    return error;
  }
};

// =========== End: ADPlatform ===========