import { IAdvertiser } from "api/types/_advertiser";
import { IAdvertiserAgency } from "api/types/_advertiserAgency";
import { IAgency } from "api/types/_agency";
import { Option } from "api/types/_public";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import ModalConfirm from "components/Common/ModalConfirm";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_ADVERTISER_OPTIONS, TYPE_ADVERTISER_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { getAdvertiser, getGenerateApiKeyAdvertiser, postAdvertiser, putAdvertiser } from "store/thunks";

import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IAdvertiser | null;
    agencies?: IAdvertiserAgency[]
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormAdvertiser = ({
    isModal = false,
    id,
    detail,
    agencies = [],
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [keyShow, setKeyShow] = useState<boolean>(false);

    const [idConfirmRefreshKey, setIdConfirmRefreshKey] = useState<number | null>(null);
    const [isConfirmRefreshKeyLoading, setIsConfirmRefreshKeyLoading] = useState<boolean>(false);

    const AGENCY_OPTIONS = (agencies?.map((item) => ({ label: item?.name, value: String(item?.id) })) || []) as Option[];

    const TYPE_ADVERTISER_OPTIONS_LANG = TYPE_ADVERTISER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const STATUS_ADVERTISER_OPTIONS_LANG = STATUS_ADVERTISER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name,
                business_registration_number: values?.business_registration_number,
                website_url: values?.website_url,
                contact_name: values?.contact_name,
                contact_phone: values?.contact_phone,
                contact_email: values?.contact_email,
                memo: values?.memo,
                budget: Number(values?.budget || 0),
                ...(values?.agency?.value ? { advertiser_agency_id: values?.agency?.value ? Number(values?.agency?.value) : '' } : {}),
                type: Number(values?.type ? values?.type?.value : TYPE_ADVERTISER_OPTIONS_LANG[0]?.value), //  1 | 2
                status: Number(values?.status ? values?.status?.value : STATUS_ADVERTISER_OPTIONS_LANG[0]?.value), // 0 | 1,
            };
            const response: any = detail?.id ? await putAdvertiser(detail?.id, data) : await postAdvertiser(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("This is required")}`),
        business_registration_number: Yup.string().nullable(),
        website_url: Yup.string().required(`${t("This is required")}`),
        contact_name: Yup.string().nullable(),
        contact_phone: Yup.string().matches(/^\d+$/, `${t('Phone number should only contain digits')}`).nullable(),
        contact_email: Yup.string().email(`${t('Please enter a valid email address')}`).nullable(),
        memo: Yup.string().nullable(),
        budget: Yup.string().nullable(),
        agency: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        type: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable(),
        status: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            business_registration_number: '',
            website_url: '',
            contact_name: '',
            contact_phone: '',
            contact_email: '',
            memo: '',
            budget: '0',
            agency: null,
            type: null,
            api_key: '',
            api_secret: '',
            status: null,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        // const v_code = valueDefault?.code;
        const v_name = valueDefault?.name;
        const v_business_registration_number = valueDefault?.business_registration_number;
        const v_website_url = valueDefault?.website_url;
        const v_contact_name = valueDefault?.contact_name;
        const v_contact_phone = valueDefault?.contact_phone;
        const v_contact_email = valueDefault?.contact_email;
        const v_memo = valueDefault?.memo;
        const v_budget = valueDefault?.budget;
        const v_api_key = valueDefault?.api_key || '';
        const v_api_secret = valueDefault?.api_secret || '';
        const v_status = STATUS_ADVERTISER_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.status)
        );
        const v_type = TYPE_ADVERTISER_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.type)
        );
        const v_agency = AGENCY_OPTIONS?.find((item) => (String(item?.value) === String(valueDefault?.advertiser_agency_id))) || null;

        // formik.setFieldValue("code", v_code);
        formik.setFieldValue("name", v_name);

        formik.setFieldValue("business_registration_number", v_business_registration_number);
        formik.setFieldValue("website_url", v_website_url);
        formik.setFieldValue("contact_name", v_contact_name);
        formik.setFieldValue("contact_phone", v_contact_phone);
        formik.setFieldValue("contact_email", v_contact_email);
        formik.setFieldValue("memo", v_memo);
        formik.setFieldValue("budget", v_budget);
        formik.setFieldValue("status", v_status);
        formik.setFieldValue("type", v_type);
        formik.setFieldValue("api_key", v_api_key);
        formik.setFieldValue("api_secret", v_api_secret);
        formik.setFieldValue("agency", v_agency);

    };


    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            const getDetail = async () => {
                try {
                    const response: any = await getAdvertiser(detail?.id);
                    if (response?.code === 200) {
                        handleSetValueForm(response?.data || detail);
                    } else {
                        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
                    }
                } catch (error: any) {
                    toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
                    return error;
                }
            }
            getDetail();
        } else {
            formik.setFieldValue("website_url", 'https://');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);


    const onCloseConfirmRefreshKeyClick = () => {
        setIdConfirmRefreshKey((_prev) => null);
    };

    const handleActionRefreshKey = async () => {
        if (!idConfirmRefreshKey) {
            return;
        }
        try {
            setIsConfirmRefreshKeyLoading((_prev) => true);
            const response: any = await getGenerateApiKeyAdvertiser(idConfirmRefreshKey);
            if (response?.data) {
                setIsConfirmRefreshKeyLoading((_prev) => false);
                setIdConfirmRefreshKey((_prev) => null);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                formik.setFieldValue("api_key", response?.data?.api_key);
                formik.setFieldValue("api_secret", response?.data?.api_secret);
                triggerRefresh && triggerRefresh(false);
            } else {
                setIsConfirmRefreshKeyLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmRefreshKeyLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };
    const copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand("copy", true, text);
        }
        toast(`${t("Copied Successfully")}`, CONFIG_OPTION_TOAST_NORMAL);
    };

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col md={12} lg={12} >
                                                <div>
                                                    <label className="form-label">
                                                        {t("Status")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_ADVERTISER_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded mt-1"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.status ||
                                                            STATUS_ADVERTISER_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("status", event)
                                                        }
                                                        colors={["danger", "success"]}
                                                    />
                                                    {formik.touched.status && formik.errors.status ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.status}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Type")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="type"
                                                        dataList={TYPE_ADVERTISER_OPTIONS_LANG || []}
                                                        placeholder={`${t("Type")}...`}
                                                        className="dropdown-status-rounded mt-1"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.type ||
                                                            TYPE_ADVERTISER_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("type", event)
                                                        }
                                                        colors={["danger", "success", "primary"]}
                                                    />
                                                    {formik.touched.type && formik.errors.type ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Advertiser Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Advertiser Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Website")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="website_url"
                                                        name="website_url"
                                                        autocomplete={false}
                                                        value={formik?.values?.website_url}
                                                        placeholder={`${t("Website")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("website_url", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.website_url && formik.errors.website_url ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.website_url}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Budget")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="budget"
                                                        name="budget"
                                                        autocomplete={false}
                                                        value={formatNumberWithCommas(formik?.values?.budget || '')}
                                                        placeholder={`${t("Budget")}...`}
                                                        onChange={(event: any) => {
                                                            const input = event.target.value;
                                                            const onlyNumbers = input.replace(/\D/g, "");
                                                            const s = Number(onlyNumbers);
                                                            formik.setFieldValue("budget", s);
                                                        }
                                                        }
                                                        className="pe-5"
                                                    />
                                                    <div
                                                        style={{ bottom: 10, right: 20 }}
                                                        className="position-absolute fw-medium text-secondary"
                                                    >
                                                        KRW
                                                    </div>
                                                    {formik.errors.budget ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.budget}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Agency")}
                                                    </label>
                                                    <DropdownOption
                                                        name="agency"
                                                        dataList={AGENCY_OPTIONS || []}
                                                        placeholder={`${t("Agency")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={formik?.values?.agency}
                                                        onChangeSelect={(event: any) => formik.setFieldValue("agency", event)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('---'), value: '' }}
                                                    />
                                                    {formik.touched.agency && formik.errors.agency ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.agency}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Business Registration Number")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="business_registration_number"
                                                        name="business_registration_number"
                                                        autocomplete={false}
                                                        value={formik?.values?.business_registration_number}
                                                        placeholder={`${t("Business Registration Number")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("business_registration_number", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.business_registration_number && formik.errors.business_registration_number ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.business_registration_number}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {!!detail?.is_preview && (<>
                                                <Col md={12}>
                                                    <label className="form-label">
                                                        {t("Advertiser API Key")}
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="position-relative w-100 ">
                                                            <Input
                                                                className="form-control"
                                                                id="api_key"
                                                                name="api_key"
                                                                disabled={true}
                                                                type="text"
                                                                value={formik?.values?.api_key}
                                                                placeholder={`${t("Advertiser API Key")}...`}
                                                            />
                                                        </div>
                                                        <button
                                                            className="btn btn-sm btn-soft-success ms-2 fs-14"
                                                            onClick={() =>
                                                                copyTextToClipboard(formik?.values?.api_key || '')
                                                            }
                                                            type="button"
                                                            style={{ borderRadius: 'var(--vz-border-radius)' }}
                                                        >
                                                            <i className="ri-file-copy-line align-middle"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <label className="form-label">
                                                        {t("Advertiser API Secret")}
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="position-relative auth-pass-inputgroup w-100 ">
                                                            <Input
                                                                className="form-control"
                                                                id="api_secret"
                                                                name="api_secret"
                                                                disabled={true}
                                                                type={keyShow ? "text" : "password"}
                                                                value={formik?.values?.api_secret}
                                                                placeholder={`${t("Advertiser API Secret")}...`}
                                                            />
                                                            <button
                                                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                                                onClick={() => setKeyShow(!keyShow)}
                                                                type="button"
                                                                id="password-addon"

                                                            >
                                                                <i className="ri-eye-fill align-middle"></i>
                                                            </button>
                                                        </div>

                                                        <button
                                                            className="btn btn-sm btn-soft-primary ms-2 fs-14"
                                                            onClick={() =>
                                                                setIdConfirmRefreshKey((prev) => Number(detail?.id))
                                                            }
                                                            type="button"
                                                            style={{ borderRadius: 'var(--vz-border-radius)' }}
                                                        >
                                                            <i className="ri-refresh-line align-middle"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-sm btn-soft-success ms-2 fs-14"
                                                            onClick={() =>
                                                                copyTextToClipboard(formik?.values?.api_secret || '')
                                                            }
                                                            type="button"
                                                            style={{ borderRadius: 'var(--vz-border-radius)' }}
                                                        >
                                                            <i className="ri-file-copy-line align-middle"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            </>)}
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Name")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_name"
                                                        name="contact_name"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_name}
                                                        placeholder={`${t("Contact Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_name && formik.errors.contact_name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Phone")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_phone"
                                                        name="contact_phone"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_phone}
                                                        placeholder={`${t("Contact Phone")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_phone", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_phone && formik.errors.contact_phone ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_phone}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Email")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_email"
                                                        name="contact_email"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_email}
                                                        placeholder={`${t("Contact Email")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_email", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_email && formik.errors.contact_email ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Memo")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="memo"
                                                        name="memo"
                                                        autocomplete={false}
                                                        value={formik?.values?.memo}
                                                        placeholder={`${t("Memo")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("memo", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.memo && formik.errors.memo ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.memo}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '160px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Advertiser") : t("Button Create Advertiser")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
            <ModalConfirm
                textButtonConfirm={t("Button Refresh Key Advertiser")}
                classButtonConfirm="btn-soft-primary"
                classIconButtonConfirm="ri-refresh-line"
                header={t("Button Refresh Key Advertiser")}
                title={t("Do you want to refresh key of advertiser!")}
                content={t(
                    "After confirming the refresh action, the data will be updated and cannot be recovered. Do you want to proceed with the refresh action."
                )}
                isShowIcon={false}
                isOpen={!!idConfirmRefreshKey}
                isLoading={isConfirmRefreshKeyLoading}
                onClose={onCloseConfirmRefreshKeyClick}
                onConfirm={handleActionRefreshKey}
            />

        </React.Fragment >
    );
};

export default FormAdvertiser;
