import { IAdvertiserAgency } from "api/types/_advertiserAgency";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { ADVERTISER_CPC_TYPE, STATUS_ADVERTISER_AGENCY_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteAdvertiserAgency, getAdvertiserAgencies as onGetAdvertiserAgencies } from "../../../store/thunks";
import ConfigAgencyCPC from "./ConfigAgencyCPC";
import FormAccount from "./FormAccount";
import FormAdvertiserAgency from "./FormAdvertiserAgency";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const ManagementAdvertiserAgency = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_ADVERTISER_AGENCY_OPTIONS_LANG = STATUS_ADVERTISER_AGENCY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        name: withDefault(StringParam, ''),
        is_active: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Management;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            advertiserAgencies: state.advertiserAgencies,
            isAdvertiserAgencySuccess: state.isAdvertiserAgencySuccess,
            isAdvertiserAgencyLoading: state.isAdvertiserAgencyLoading,
            error: state.error,
        })
    );

    const { advertiserAgencies, isAdvertiserAgencyLoading } = useSelector(ManagementProperties);

    const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_ADVERTISER_AGENCY_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_active))[0]);

    const [advertiserAgencyDetail, setAdvertiserAgencyDetail] = useState<IAdvertiserAgency | null>(null);

    const [advertiserAgencyDetailChangeAccountLogin, setAdvertiserAgencyDetailChangeAccountLogin] = useState<IAdvertiserAgency | null>(null);

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    const [advertiserAgencyShowCPC, setAdvertiserAgencyShowCPC] = useState<any>(null);

    useEffect(() => {
        dispatch(onGetAdvertiserAgencies(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            name: nameSearch || undefined,
            is_active: statusSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            name: undefined,
            is_active: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setNameSearch("");
        setStatusSearch(null);
    };

    const handleRefresh = (isClose: boolean = true) => {
        if (isClose) { setAdvertiserAgencyDetail((_prev) => null); }
        resetData();
    }

    const handleRefreshAdvertiserAgencyDetailChangeAccountLogin = () => {
        setAdvertiserAgencyDetailChangeAccountLogin((_prev) => null);
        resetData();
    }


    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            // {
            //     Header: t('Code'),
            //     accessor: "code",
            //     filterable: true,
            //     sortable: false,
            //     Cell: (cell: any) => (
            //         <>
            //             <div style={{ minWidth: '40px' }}>{cell?.value}</div>
            //         </>
            //     ),
            // },
            {
                Header: t('Agency Name'),
                accessor: "name",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => (
                    <>
                        <div className="text-start" style={{ minWidth: '120px' }}>{cell?.value ?? '-'}</div>
                    </>
                ),
            },
            {
                Header: t('Advertisers'),
                accessor: "advertisers",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => (
                    <div style={{ minWidth: '200px' }}>
                        {cell?.value?.map((item: any, index: number) => (
                            <>
                                <span className='text-start text-normal btn btn-sm btn-soft-primary me-2 mb-1 mt-1' key={index}>
                                    {item?.name || ''}
                                </span>
                            </>
                        ))}
                    </div>
                ),
            },
            {
                Header: t('CPC'),
                accessor: "cpc",
                filterable: true,
                sortable: false,
                description: t('Cost Per Click'),
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;

                    return (
                        <>
                            <div className="text-center" style={{ minWidth: '60px' }}>{formatNumberWithCommas(item?.cpc || 0)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Contact Name'),
                accessor: "contact_name",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => (
                    <>
                        <div className="text-start" style={{ minWidth: '120px' }}>{cell?.value ?? '-'}</div>
                    </>
                ),
            },
            {
                Header: t('Contact Phone'),
                accessor: "contact_phone",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => (
                    <>
                        <div className="text-start" style={{ minWidth: '120px' }}>{cell?.value ?? '-'}</div>
                    </>
                ),
            },
            {
                Header: t('Contact Email'),
                accessor: "contact_email",
                filterable: false,
                sortable: false,
                thClass: 'text-start',
                Cell: (cell: any) => (
                    <>
                        <div className="text-start" style={{ minWidth: '120px' }}>{cell?.value ?? '-'}</div>
                    </>
                ),
            },
            {
                Header: t('Status'),
                accessor: "status",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '80px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success'][cell?.value] || 'secondary'}`}>{STATUS_ADVERTISER_AGENCY_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail Agency')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdvertiserAgencyDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Config CPC')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="CPC">
                                        <Link className="btn btn-sm btn-soft-warning d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdvertiserAgencyShowCPC((_prev: any) => (item));
                                            }}>
                                            <i className="ri-shield-star-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_AGENCY_CONFIG_ACCOUNT_LOGIN, userPermissions) && (<TooltipCustom
                                    title={t('Button Update Account Login Agency')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-success d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdvertiserAgencyDetailChangeAccountLogin((_prev) => item);
                                            }}>
                                            <i className="ri-user-3-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_AGENCY_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Update Agency')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdvertiserAgencyDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_AGENCY_DELETE, userPermissions) && (<TooltipCustom
                                    title={t('Button Delete Agency')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), STATUS_ADVERTISER_AGENCY_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleCallAllOption = async () => {
        try {
            const []: any = await Promise.all([]);

        } catch (error: any) {
            return error;
        }
    };

    // Begin::Delete
    const handleConfirmDelete = (item: IAdvertiserAgency) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteAdvertiserAgency(idDelete);
            if (response?.code === 200) {
                dispatch(onGetAdvertiserAgencies(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    const handleActionRefresh = () => {
        dispatch(onGetAdvertiserAgencies(query));
    }

    useEffect(() => {
        handleCallAllOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = `${t("Advertiser Agency Management")} - ${t("Ads Management")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Advertiser Agency Management')} pageTitle={t('Ads Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_ADVERTISER_AGENCY, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={advertiserAgencies?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_AGENCY_CREATE, userPermissions) && (<div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setAdvertiserAgencyDetail((_prev) => ({} as IAdvertiserAgency))}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Create Agency')}
                                                    </button>
                                                </div>
                                            </div>)}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={6} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Agency Name')} isShow={!!nameSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Agency Name')}...`}
                                                        value={nameSearch}
                                                        onChange={(e) => setNameSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={9} xxl={9} className="hstack gap-1 justify-content-center justify-content-sm-between justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isAdvertiserAgencyLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={advertiserAgencies?.list?.length ? advertiserAgencies?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={advertiserAgencies?.pagination?.total}
                                            customPageCount={Math.ceil(Number(advertiserAgencies?.pagination?.total) / Number(advertiserAgencies?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isAdvertiserAgencyLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {((windowSize?.width >= MAX_WIDTH_SCREEN)) && (
                                <Offcanvas
                                    fade
                                    scrollable={false}
                                    direction="end"
                                    isOpen={(advertiserAgencyDetail !== null)}
                                    id="offcanvasExample"
                                    className='off-canvas-lg'
                                >
                                    <OffcanvasHeader className="bg-light" toggle={() => setAdvertiserAgencyDetail((_prev) => null)}>
                                        <h5 className="fs-16 mb-1">{!!advertiserAgencyDetail?.id ? (!!advertiserAgencyDetail?.is_preview ? t('Button Detail Agency') : t('Button Update Agency')) : t('Button Create Agency')}</h5>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="color-scrollbar-os">
                                        <FormAdvertiserAgency detail={advertiserAgencyDetail} triggerRefresh={handleRefresh} handleClose={() => setAdvertiserAgencyDetail((_prev) => null)} />
                                    </OffcanvasBody>
                                </Offcanvas>
                            )}
                            {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                                isOpen={advertiserAgencyDetail !== null}
                                centered={true}
                                size="md"
                                toggle={() => setAdvertiserAgencyDetail((_prev) => null)}
                                keyboard={true}
                            >
                                <ModalHeader toggle={() => setAdvertiserAgencyDetail((_prev) => null)}>
                                    {!!advertiserAgencyDetail?.id ? (!!advertiserAgencyDetail?.is_preview ? t('Button Detail Agency') : t('Button Update Agency')) : t('Button Create Agency')}
                                </ModalHeader>
                                <ModalBody className="">
                                    <FormAdvertiserAgency detail={advertiserAgencyDetail} triggerRefresh={handleRefresh} handleClose={() => setAdvertiserAgencyDetail((_prev) => null)} />
                                </ModalBody>
                            </Modal>)}
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <Modal
                isOpen={advertiserAgencyDetailChangeAccountLogin !== null}
                centered={true}
                size="md"
                toggle={() => setAdvertiserAgencyDetailChangeAccountLogin((_prev) => null)}
                keyboard={true}
            >
                <ModalHeader toggle={() => setAdvertiserAgencyDetailChangeAccountLogin((_prev) => null)}>
                    {t('Button Update Account Login Agency')}
                </ModalHeader>
                <ModalBody className="">
                    <FormAccount detail={advertiserAgencyDetailChangeAccountLogin} triggerRefresh={handleRefreshAdvertiserAgencyDetailChangeAccountLogin} handleClose={() => setAdvertiserAgencyDetailChangeAccountLogin((_prev) => null)} />
                </ModalBody>
            </Modal>
            <Modal isOpen={!!advertiserAgencyShowCPC} centered={true} scrollable={true} size="lg">
                <ModalHeader toggle={() => setAdvertiserAgencyShowCPC((prev: any) => (prev ? null : prev))}>
                    {t('Button Config CPC')}
                </ModalHeader>
                <ModalBody className="py-3 pt-0 px-4">
                    <div style={{ minHeight: '450px' }}>
                        <Row>
                            <Col md={12} lg={12}>
                                <ConfigAgencyCPC
                                    type={ADVERTISER_CPC_TYPE.ADVERTISER_AGENCY}
                                    advertiserAgencyId={advertiserAgencyShowCPC?.id}
                                    advertiserAgency={advertiserAgencyShowCPC}
                                    handleActionRefresh={handleActionRefresh}
                                    handleCloseModal={() => setAdvertiserAgencyShowCPC((prev: any) => (null))}
                                />
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            <ModalConfirm
                header={t('Button Delete Agency')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />

        </React.Fragment>
    );
};

export default ManagementAdvertiserAgency;