import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import Hashtag from "components/Common/Hashtag";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Card, Col, Container, Input, Label, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getMonitorSystemUuidConfigs, getExceptKeywords as onGetExceptKeywords, postExceptKeyword } from "../../../store/thunks";

interface Tag {
    id: string;
    text: string
}
const MonitorConfiguration = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();

    const [keywords, setKeywords] = useState<Tag[]>([]);

    const [hostNames, setHostNames] = useState<Tag[]>([]);

    const [pathNames, setPathNames] = useState<Tag[]>([]);

    const [keywordNames, setKeywordNames] = useState<Tag[]>([]);

    const [autoBlockUuid, setAutoBlockUuid] = useState<number | boolean>(0);

    const [exceedRequestPerHour, setExceedRequestPerHour] = useState<number | string>(0);

    const [belowClickPerHour, setBelowClickPerHour] = useState<number | string>(0);

    const [exceptFirstTimeVisitor, setExceptFirstTimeVisitor] = useState<number | boolean>(0);
    const [exceptPCVisitors, setExceptPCVisitors] = useState<number | boolean>(0);
    const [exceptMobileVisitors, setExceptMobileVisitors] = useState<number | boolean>(0);
    const [numberOfApplicableVisitors, setNumberOfApplicableVisitors] = useState<number | string>(0);

    const [exceptAccessHosts, setExceptAccessHosts] = useState<number | boolean>(0);
    const [hostExceptPCVisitors, setHostExceptPCVisitors] = useState<number | boolean>(0);
    const [hostExceptMobileVisitors, setHostExceptMobileVisitors] = useState<number | boolean>(0);

    const [exceptGeneralVisitors, setExceptGeneralVisitors] = useState<number | boolean>(0);
    const [referrerApplicationPeriod, setReferrerApplicationPeriod] = useState<number | string>(0);
    const [numberOfApplicableImpressions, setNumberOfApplicableImpressions] = useState<number | string>(0);

    const [exceptAccessReferralPaths, setExceptAccessReferralPaths] = useState<number | boolean>(0);
    const [pathExceptPCVisitors, setPathExceptPCVisitors] = useState<number | boolean>(0);
    const [pathExceptMobileVisitors, setPathExceptMobileVisitors] = useState<number | boolean>(0);


    const [exceptFilterReferrals, setExceptFilterReferrals] = useState<number | boolean>(0);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Monitor;

    const MonitorProperties = createSelector(
        selectLayoutState,
        (state) => ({
            exceptKeywords: state.exceptKeywords,
            isExceptKeywordSuccess: state.isExceptKeywordSuccess,
            isExceptKeywordLoading: state.isExceptKeywordLoading,
            error: state.error,
        })
    );

    const { exceptKeywords, isExceptKeywordLoading } = useSelector(MonitorProperties);

    const handleUpdateExceptKeywords = async () => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                auto_block_uuid: Number(autoBlockUuid),
                exceed_request_per_hour: Number(exceedRequestPerHour),
                below_click_per_hour: Number(belowClickPerHour),
                keywords: keywords?.map((key) => key?.text) || [],
                except_first_time_visitor: {
                    except_first_time_visitor: Number(exceptFirstTimeVisitor),
                    except_pc_visitors: Number(exceptPCVisitors),
                    except_mobile_visitors: Number(exceptMobileVisitors),
                    number_of_applicable_visitors: Number(numberOfApplicableVisitors)
                },
                except_general_visitors: {
                    except_general_visitors: Number(exceptGeneralVisitors),
                    referrer_application_period: Number(referrerApplicationPeriod),
                    number_of_applicable_impressions: Number(numberOfApplicableImpressions)
                },
                except_access_hosts: {
                    except_access_hosts: Number(exceptAccessHosts),
                    host_names: hostNames?.map((key) => key?.text) || [],
                    except_pc_visitors: Number(hostExceptPCVisitors),
                    except_mobile_visitors: Number(hostExceptMobileVisitors),
                },
                except_access_referral_paths: {
                    except_access_referral_paths: Number(exceptAccessReferralPaths),
                    path_names: pathNames?.map((key) => key?.text) || [],
                    except_pc_visitors: Number(pathExceptPCVisitors),
                    except_mobile_visitors: Number(pathExceptMobileVisitors),
                },
                filter_referral_url: {
                    filter_referral_url: Number(exceptFilterReferrals),
                    keywords: keywordNames?.map((key) => key?.text) || [],
                }

            };
            const response: any = await postExceptKeyword(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }

    useEffect(() => {
        dispatch(onGetExceptKeywords({}));
    }, []);

    useEffect(() => {
        if (exceptKeywords) {
            setKeywords((prev) => (exceptKeywords?.map((item: string) => ({ id: item, text: item }))));
        }
    }, [exceptKeywords]);

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [res]: any = await Promise.all([getMonitorSystemUuidConfigs()]);

                const configs = res?.data || null;

                setAutoBlockUuid(Number(configs?.auto_block_uuid));

                setExceedRequestPerHour(configs?.exceed_request_per_hour || 0);

                setBelowClickPerHour(configs?.below_click_per_hour || 0);


                setExceptFirstTimeVisitor(Number(configs?.except_first_time_visitor?.except_first_time_visitor));
                setExceptPCVisitors(Number(configs?.except_first_time_visitor?.except_pc_visitors));
                setExceptMobileVisitors(Number(configs?.except_first_time_visitor?.except_mobile_visitors));
                setNumberOfApplicableVisitors(configs?.except_first_time_visitor?.number_of_applicable_visitors || 0);

                setExceptAccessHosts(Number(configs?.except_access_hosts?.except_access_hosts));
                setHostExceptPCVisitors(Number(configs?.except_access_hosts?.except_pc_visitors));
                setHostExceptMobileVisitors(Number(configs?.except_access_hosts?.except_mobile_visitors));
                setHostNames((prev) => ((configs?.except_access_hosts?.host_names || [])?.map((item: string) => ({ id: item, text: item }))));

                setExceptGeneralVisitors(configs?.except_general_visitors?.except_general_visitors || 0);
                setReferrerApplicationPeriod(configs?.except_general_visitors?.referrer_application_period || 0);
                setNumberOfApplicableImpressions(configs?.except_general_visitors?.number_of_applicable_impressions || 0);

                setExceptAccessReferralPaths(Number(configs?.except_access_referral_paths?.except_access_referral_paths));
                setPathExceptPCVisitors(Number(configs?.except_access_referral_paths?.except_pc_visitors));
                setPathExceptMobileVisitors(Number(configs?.except_access_referral_paths?.except_mobile_visitors));
                setPathNames((prev) => ((configs?.except_access_referral_paths?.path_names || [])?.map((item: string) => ({ id: item, text: item }))));


                setExceptFilterReferrals(Number(configs?.filter_referral_url?.filter_referral_url || 0));
                setKeywordNames((prev) => ((configs?.filter_referral_url?.keywords || [])?.map((item: string) => ({ id: item, text: item }))));

            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);


    useEffect(() => {
        document.title = `${t("Monitor Configuration")} - ${t('Monitor')} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Monitor Configuration')} pageTitle={t('Monitor')} />
                    {isHavePermissionRole(ROLES_FOR_APP.MONITOR_CONFIGURATION_LIST, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={0} md={2} className="d-none d-md-block"></Col>
                                            <Col sm={12} md={8} className='date-picker-wrapper-custom mt-0 mt-md-2'>
                                                {/* <div className="border border-dashed border-primary p-4 position-relative d-none" style={{ borderRadius: '8px' }}>
                                                <span className="h6 position-absolute text-primary mb-0" style={{
                                                    top: 0,
                                                    transform: 'translate(-5px, -50%)',
                                                    backgroundColor: 'var(--vz-card-bg)',
                                                    padding: '0 5px',
                                                }}
                                                >{t('Automatic Block UUID')}</span>
                                                <Col sm={12} className="mb-3">
                                                    <div className="form-check form-switch mb-0">
                                                        <Input className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="auto_block_uuid"
                                                            name="auto_block_uuid"
                                                            checked={!!autoBlockUuid}
                                                            onChange={(event: any) => setAutoBlockUuid(Number(event?.target.checked))}
                                                        />
                                                        <Label className="form-check-label" for="auto_block_uuid">{t('Automatic Block UUID')}</Label>
                                                        <TooltipCustom
                                                            title={t("If all the conditions set for the day are met, the UUID is automatically blocked")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={4} className="mb-3">
                                                    <div className="mb-0">
                                                        <Label className="form-label" for="exceed_request_per_hour">{t('Daily request count (Exceeds)')}</Label>
                                                        <TooltipCustom
                                                            title={t("If the number of requests exceeds the specified limit, it automatically becomes a target for blocking")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                        <Input className="form-control text-start"
                                                            type="text"
                                                            id="exceed_request_per_hour"
                                                            name="exceed_request_per_hour"
                                                            value={formatNumberWithCommas(exceedRequestPerHour)}
                                                            onChange={(event: any) => {
                                                                const input = event.target.value;
                                                                const onlyNumbers = input.replace(/\D/g, '');
                                                                const s = Number(onlyNumbers);
                                                                setExceedRequestPerHour(s)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={4} className="mb-0">
                                                    <div className="mb-0">
                                                        <Label className="form-label" for="below_click_per_hour">{t('Daily click count (Below)')}</Label>
                                                        <TooltipCustom
                                                            title={t("If the number of clicks is less than the specified amount, it automatically becomes a target for blocking")}
                                                            className="d-inline-block vertical-align-middle ms-1"
                                                            style={{ transform: 'translateY(1px)' }}
                                                        >
                                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                                        </TooltipCustom>
                                                        <Input className="form-control text-start"
                                                            type="text"
                                                            id="below_click_per_hour"
                                                            name="below_click_per_hour"
                                                            value={formatNumberWithCommas(belowClickPerHour)}
                                                            onChange={(event: any) => {
                                                                const input = event.target.value;
                                                                const onlyNumbers = input.replace(/\D/g, '');
                                                                const s = Number(onlyNumbers);
                                                                setBelowClickPerHour(s)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </div> */}
                                                <div className="border border-dashed border-primary p-4 mb-3 mt-3 mt-md-4 position-relative" style={{ borderRadius: '8px' }}>
                                                    <span className="h6 position-absolute text-primary mb-0" style={{
                                                        top: 0,
                                                        transform: 'translate(-5px, -50%)',
                                                        backgroundColor: 'var(--vz-card-bg)',
                                                        padding: '0 5px',
                                                    }}
                                                    >{t('Except Keyword')}</span>
                                                    <Col xxl={12}>
                                                        <div style={{ minHeight: '100px' }}>
                                                            <label className="form-label">
                                                                {t("Except Keyword")}
                                                            </label>
                                                            <TooltipCustom
                                                                title={t("If specific keywords is included in referral url, we show ad but it's not working 'Click Option', 'Size Option', only working normally in any cases.")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                            <Hashtag
                                                                name="tags"
                                                                placeholder={`${t("Except Keyword")}...`}
                                                                suggestions={[]}
                                                                initialValue={keywords || []}
                                                                onChangeTag={(event: any) => {
                                                                    setKeywords((prev) => event);
                                                                }}
                                                                idSelected="hashtags-CampaignFormMulti"
                                                                maxHashtags={2000}
                                                                maxHeight="150px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </div>

                                                <div className="border border-dashed border-primary p-4 position-relative mt-4" style={{ borderRadius: '8px' }}>
                                                    <span className="h6 position-absolute text-primary mb-0" style={{
                                                        top: 0,
                                                        transform: 'translate(-5px, -50%)',
                                                        backgroundColor: 'var(--vz-card-bg)',
                                                        padding: '0 5px',
                                                    }}
                                                    >{t('Except first-time visitor')}</span>
                                                    <Col sm={12} className="mb-3">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="except_first_time_visitor"
                                                                name="except_first_time_visitor"
                                                                checked={!!exceptFirstTimeVisitor}
                                                                onChange={(event: any) => setExceptFirstTimeVisitor(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="except_first_time_visitor">{t('Except first-time visitor')}</Label>
                                                            <TooltipCustom
                                                                title={t("Automatically except visitors based on UUID for the specified number of visits from their first visitor")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={4} className="mb-3">
                                                        <div className="mb-0">
                                                            <Label className="form-label" for="number_of_applicable_visitors">{t('Number of Applicable Visitors')}</Label>
                                                            <TooltipCustom
                                                                title={t("Targets visitors up to the specific visits from the first visit based on UUID")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                            <Input className="form-control text-start"
                                                                type="text"
                                                                id="number_of_applicable_visitors"
                                                                name="number_of_applicable_visitors"
                                                                value={formatNumberWithCommas(numberOfApplicableVisitors)}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, '');
                                                                    const s = Number(onlyNumbers);
                                                                    setNumberOfApplicableVisitors(s)
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mb-3">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="except_pc_visitors"
                                                                name="except_pc_visitors"
                                                                checked={!!exceptPCVisitors}
                                                                onChange={(event: any) => setExceptPCVisitors(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="except_pc_visitors">{t('Except PC Visitors')}</Label>
                                                            <TooltipCustom
                                                                title={t("If the visitor is accessing via PC, both IP and UUID will be excepted")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mb-0">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="except_mobile_visitors"
                                                                name="except_mobile_visitors"
                                                                checked={!!exceptMobileVisitors}
                                                                onChange={(event: any) => setExceptMobileVisitors(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="except_mobile_visitors">{t('Except Mobile Visitors')}</Label>
                                                            <TooltipCustom
                                                                title={t("If the visitor is accessing via Mobile, the UUID will be excepted")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                </div>
                                                <div className="border border-dashed border-primary p-4 position-relative mt-4" style={{ borderRadius: '8px' }}>
                                                    <span className="h6 position-absolute text-primary mb-0" style={{
                                                        top: 0,
                                                        transform: 'translate(-5px, -50%)',
                                                        backgroundColor: 'var(--vz-card-bg)',
                                                        padding: '0 5px',
                                                    }}
                                                    >{t('Except general visitors')}</span>
                                                    <Col sm={12} className="mb-3">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="except_general_visitors"
                                                                name="except_general_visitors"
                                                                checked={!!exceptGeneralVisitors}
                                                                onChange={(event: any) => setExceptGeneralVisitors(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="except_general_visitors">{t('Except general visitors')}</Label>
                                                            <TooltipCustom
                                                                title={t("Automatically except visitors based on UUID who have had no referrer from the specified date to the present and have exceeded the specified number of impression")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={4} className="mb-3">
                                                        <div className="mb-0">
                                                            <Label className="form-label" for="referrer_application_period">{t('Referrer Application Period')}</Label>
                                                            <TooltipCustom
                                                                title={t("Targets visitors without referrers based on UUID in the last  specific days")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                            <div className="position-relative">
                                                                <Input className="form-control text-start"
                                                                    type="text"
                                                                    id="referrer_application_period"
                                                                    name="referrer_application_period"
                                                                    value={formatNumberWithCommas(referrerApplicationPeriod)}
                                                                    onChange={(event: any) => {
                                                                        const input = event.target.value;
                                                                        const onlyNumbers = input.replace(/\D/g, '');
                                                                        const s = Number(onlyNumbers);
                                                                        setReferrerApplicationPeriod(s)
                                                                    }}
                                                                />
                                                                <div
                                                                    style={{ bottom: 10, right: 20 }}
                                                                    className="position-absolute fw-medium text-secondary"
                                                                >
                                                                    {t('days')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} md={4} className="mb-0">
                                                        <div className="mb-0">
                                                            <Label className="form-label" for="number_of_applicable_impressions">{t('Number of Applicable Impressions')}</Label>
                                                            <TooltipCustom
                                                                title={t("Except UUID of visitors without referrers who have specific number or more impressions in the above period")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                            <Input className="form-control text-start"
                                                                type="text"
                                                                id="number_of_applicable_impressions"
                                                                name="number_of_applicable_impressions"
                                                                value={formatNumberWithCommas(numberOfApplicableImpressions)}
                                                                onChange={(event: any) => {
                                                                    const input = event.target.value;
                                                                    const onlyNumbers = input.replace(/\D/g, '');
                                                                    const s = Number(onlyNumbers);
                                                                    setNumberOfApplicableImpressions(s)
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                </div>
                                                <div className="border border-dashed border-primary p-4 position-relative mt-4" style={{ borderRadius: '8px' }}>
                                                    <span className="h6 position-absolute text-primary mb-0" style={{
                                                        top: 0,
                                                        transform: 'translate(-5px, -50%)',
                                                        backgroundColor: 'var(--vz-card-bg)',
                                                        padding: '0 5px',
                                                    }}
                                                    >{t('Except Access Hosts')}</span>
                                                    <Col sm={12} className="mb-3">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="except_access_hosts"
                                                                name="except_access_hosts"
                                                                checked={!!exceptAccessHosts}
                                                                onChange={(event: any) => setExceptAccessHosts(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="except_access_hosts">{t('Except Access Hosts')}</Label>
                                                            <TooltipCustom
                                                                title={t("Automatically except if the specified keyword is present in the host of the referrer URL")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={12} className="mb-2">
                                                        <div style={{ minHeight: '80px' }}>
                                                            <label className="form-label">
                                                                {t("Host Names")}
                                                            </label>
                                                            <TooltipCustom
                                                                title={t("If the specified keyword is present in the host of the referrer URL, Example: Keyword: cms, Target Host: cms.domain.xyz")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                            <Hashtag
                                                                name="tags"
                                                                placeholder={`${t("Host Names")}...`}
                                                                suggestions={[]}
                                                                initialValue={hostNames || []}
                                                                onChangeTag={(event: any) => {
                                                                    setHostNames((prev) => event);
                                                                }}
                                                                idSelected="hashtags-CampaignFormMulti"
                                                                maxHashtags={2000}
                                                                maxHeight="150px"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mb-3">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="host_except_pc_visitors"
                                                                name="host_except_pc_visitors"
                                                                checked={!!hostExceptPCVisitors}
                                                                onChange={(event: any) => setHostExceptPCVisitors(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="host_except_pc_visitors">{t('Except PC Visitors')}</Label>
                                                            <TooltipCustom
                                                                title={t("If the visitor is accessing via PC, both IP and UUID will be excepted")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mb-0">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="host_except_mobile_visitors"
                                                                name="host_except_mobile_visitors"
                                                                checked={!!hostExceptMobileVisitors}
                                                                onChange={(event: any) => setHostExceptMobileVisitors(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="host_except_mobile_visitors">{t('Except Mobile Visitors')}</Label>
                                                            <TooltipCustom
                                                                title={t("If the visitor is accessing via Mobile, the UUID will be excepted")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>

                                                </div>
                                                <div className="border border-dashed border-primary p-4 position-relative mt-4" style={{ borderRadius: '8px' }}>
                                                    <span className="h6 position-absolute text-primary mb-0" style={{
                                                        top: 0,
                                                        transform: 'translate(-5px, -50%)',
                                                        backgroundColor: 'var(--vz-card-bg)',
                                                        padding: '0 5px',
                                                    }}
                                                    >{t('Except URL Paths')}</span>
                                                    <Col sm={12} className="mb-3">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="except_access_referral_paths"
                                                                name="except_access_referral_paths"
                                                                checked={!!exceptAccessReferralPaths}
                                                                onChange={(event: any) => setExceptAccessReferralPaths(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="except_access_referral_paths">{t('Except URL Paths')}</Label>
                                                            <TooltipCustom
                                                                title={t("Automatically except if the specified keyword is present in the path of the referrer URL")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={12} className="mb-2">
                                                        <div style={{ minHeight: '80px' }}>
                                                            <label className="form-label">
                                                                {t("Path Names")}
                                                            </label>
                                                            <TooltipCustom
                                                                title={t("If the specified keyword is present in the Path of the referrer URL, Example: Keyword: wp-admin, Target URL: domain.xyz/wp-admin")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                            <Hashtag
                                                                name="tags"
                                                                placeholder={`${t("Path Names")}...`}
                                                                suggestions={[]}
                                                                initialValue={pathNames || []}
                                                                onChangeTag={(event: any) => {
                                                                    setPathNames((prev) => event);
                                                                }}
                                                                idSelected="hashtags-CampaignFormMulti"
                                                                maxHashtags={2000}
                                                                maxHeight="150px"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mb-3">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="path_except_pc_visitors"
                                                                name="path_except_pc_visitors"
                                                                checked={!!pathExceptPCVisitors}
                                                                onChange={(event: any) => setPathExceptPCVisitors(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="path_except_pc_visitors">{t('Except PC Visitors')}</Label>
                                                            <TooltipCustom
                                                                title={t("If the visitor is accessing via PC, both IP and UUID will be excepted")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mb-0">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="path_except_mobile_visitors"
                                                                name="path_except_mobile_visitors"
                                                                checked={!!pathExceptMobileVisitors}
                                                                onChange={(event: any) => setPathExceptMobileVisitors(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="path_except_mobile_visitors">{t('Except Mobile Visitors')}</Label>
                                                            <TooltipCustom
                                                                title={t("If the visitor is accessing via Mobile, the UUID will be excepted")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                </div>

                                                <div className="border border-dashed border-primary p-4 position-relative mt-4" style={{ borderRadius: '8px' }}>
                                                    <span className="h6 position-absolute text-primary mb-0" style={{
                                                        top: 0,
                                                        transform: 'translate(-5px, -50%)',
                                                        backgroundColor: 'var(--vz-card-bg)',
                                                        padding: '0 5px',
                                                    }}
                                                    >{t('Filter Referral URL')}</span>
                                                    <Col sm={12} className="mb-3">
                                                        <div className="form-check form-switch mb-0">
                                                            <Input className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="except_filter_referral_url"
                                                                name="except_filter_referral_url"
                                                                checked={!!exceptFilterReferrals}
                                                                onChange={(event: any) => setExceptFilterReferrals(Number(event?.target.checked))}
                                                            />
                                                            <Label className="form-check-label" for="except_filter_referral_url">{t('Filter Referral URL')}</Label>
                                                            <TooltipCustom
                                                                title={t("The ad function operates only when the specified keywords are included in the referrer URL")}
                                                                className="d-inline-block vertical-align-middle ms-1"
                                                                style={{ transform: 'translateY(1px)' }}
                                                            >
                                                                <i className="ri-question-line align-bottom text-secondary" ></i>
                                                            </TooltipCustom>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={12} className="mb-2">
                                                        <div style={{ minHeight: '80px' }}>
                                                            <label className="form-label">
                                                                {t("Keyword")}
                                                            </label>
                                                            <Hashtag
                                                                name="tags"
                                                                placeholder={`${t("Keyword")}...`}
                                                                suggestions={[]}
                                                                initialValue={keywordNames || []}
                                                                onChangeTag={(event: any) => {
                                                                    setKeywordNames((prev) => event);
                                                                }}
                                                                idSelected="hashtags-CampaignFormMulti"
                                                                maxHashtags={2000}
                                                                maxHeight="150px"
                                                            />
                                                        </div>
                                                    </Col>
                                                </div>

                                                {isHavePermissionRole(ROLES_FOR_APP.MONITOR_CONFIGURATION_UPDATE, userPermissions) && (
                                                    <Col sm={12} className="hstack gap-1 justify-content-end mt-3 mt-md-3">
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-success"
                                                                onClick={handleUpdateExceptKeywords}
                                                                disabled={isLoading || isExceptKeywordLoading}
                                                                style={{ width: '130px' }}
                                                            >
                                                                {isLoading ? (<Spinner size="sm"></Spinner>) : t('Button Update')}
                                                            </button>
                                                        </div>
                                                    </Col>)}
                                            </Col>
                                            <Col sm={0} md={2}></Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment >
    );
};

export default MonitorConfiguration;