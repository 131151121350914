import { createSlice } from "@reduxjs/toolkit";
import { IAgencyReport } from "api/types/_agency";
import { IADPlatformMediaReport, IInventoryReport } from "api/types/_inventory";
import { IClickReport, IDailyReport, IHourlyReport, IMediaHourlyReport, IMediaReport } from "api/types/_media";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IRevenueReport } from "api/types/_revenue";
import { reportADPlatformMedias, reportADPlatforms, reportADTypes, reportAdvertiserAgencies, reportAdvertisers, reportAdvertising, reportAgencies, reportClick, reportCommissions, reportCoupangNasmedias, reportCoupangs, reportDaily, reportHourly, reportInventories, reportMediaHourly, reportMedias, reportMediasSales, reportMediasV2, reportRevenues, reportSettlements } from "./thunk";
import { ICommissionReport } from "api/types/_commission";
import { ISettlementReport } from "api/types/_settlement";
import { ICoupangReport } from "api/types/_coupang";
import { IAdvertiserAgencyReport, IAdvertiserReport } from "api/types/_advertiser";
import { IAdvertisingReport } from "api/types/_advertising";

export interface IState {
  medias: ResponseData<IMediaReport[]> & PaginationResponse | null,
  isMediaLoading: boolean,
  isMediaSuccess: boolean,

  mediasV2: ResponseData<IMediaReport[]> & PaginationResponse | null,
  isMediaV2Loading: boolean,
  isMediaV2Success: boolean,

  mediasSales: ResponseData<IMediaReport[]> & PaginationResponse | null,
  isMediaSalesLoading: boolean,
  isMediaSalesSuccess: boolean,

  inventories: ResponseData<IInventoryReport[]> & PaginationResponse | null,
  isInventoryLoading: boolean,
  isInventorySuccess: boolean,

  agencies: ResponseData<IAgencyReport[]> & PaginationResponse | null,
  isAgencyLoading: boolean,
  isAgencySuccess: boolean,

  revenues: ResponseData<IRevenueReport[]> & PaginationResponse | null,
  isRevenueLoading: boolean,
  isRevenueSuccess: boolean,

  commissions: ResponseData<ICommissionReport[]> & PaginationResponse | null,
  isCommissionLoading: boolean,
  isCommissionSuccess: boolean,

  settlements: ResponseData<ISettlementReport[]> & PaginationResponse | null,
  isSettlementLoading: boolean,
  isSettlementSuccess: boolean,

  daily: ResponseData<IDailyReport[]> & PaginationResponse | null,
  isDailyLoading: boolean,
  isDailySuccess: boolean,

  hourly: ResponseData<IHourlyReport[]> & PaginationResponse | null,
  isHourlyLoading: boolean,
  isHourlySuccess: boolean,

  mediaHourly: ResponseData<IMediaHourlyReport[]> & PaginationResponse | null,
  isMediaHourlyLoading: boolean,
  isMediaHourlySuccess: boolean,

  click: ResponseData<IClickReport[]> & PaginationResponse | null,
  isClickLoading: boolean,
  isClickSuccess: boolean,

  ad_platforms: ResponseData<IInventoryReport[]> & PaginationResponse | null,
  isADPlatformLoading: boolean,
  isADPlatformSuccess: boolean,

  ad_platform_medias: ResponseData<IADPlatformMediaReport[]> & PaginationResponse | null,
  isADPlatformMediaLoading: boolean,
  isADPlatformMediaSuccess: boolean,

  ad_types: ResponseData<IInventoryReport[]> & PaginationResponse | null,
  isADTypeLoading: boolean,
  isADTypeSuccess: boolean,

  coupangs: ResponseData<ICoupangReport[]> & PaginationResponse | null,
  isCoupangLoading: boolean,
  isCoupangSuccess: boolean,


  advertisers: ResponseData<IAdvertiserReport[]> & PaginationResponse | null,
  isAdvertiserLoading: boolean,
  isAdvertiserSuccess: boolean,

  advertiserAgencies: ResponseData<IAdvertiserAgencyReport[]> & PaginationResponse | null,
  isAdvertiserAgenciesLoading: boolean,
  isAdvertiserAgenciesSuccess: boolean,

  advertising: ResponseData<IAdvertisingReport[]> & PaginationResponse | null,
  isAdvertisingLoading: boolean,
  isAdvertisingSuccess: boolean,


  coupangNasmedias: ResponseData<ICoupangReport[]> & PaginationResponse | null,
  isCoupangNasmediaLoading: boolean,
  isCoupangNasmediaSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  medias: null,
  isMediaLoading: false,
  isMediaSuccess: false,

  mediasV2: null,
  isMediaV2Loading: false,
  isMediaV2Success: false,

  mediasSales: null,
  isMediaSalesLoading: false,
  isMediaSalesSuccess: false,

  inventories: null,
  isInventoryLoading: false,
  isInventorySuccess: false,

  agencies: null,
  isAgencyLoading: false,
  isAgencySuccess: false,

  revenues: null,
  isRevenueLoading: false,
  isRevenueSuccess: false,

  commissions: null,
  isCommissionLoading: false,
  isCommissionSuccess: false,

  settlements: null,
  isSettlementLoading: false,
  isSettlementSuccess: false,

  daily: null,
  isDailyLoading: false,
  isDailySuccess: false,

  hourly: null,
  isHourlyLoading: false,
  isHourlySuccess: false,

  mediaHourly: null,
  isMediaHourlyLoading: false,
  isMediaHourlySuccess: false,

  click: null,
  isClickLoading: false,
  isClickSuccess: false,

  ad_platforms: null,
  isADPlatformLoading: false,
  isADPlatformSuccess: false,

  ad_platform_medias: null,
  isADPlatformMediaLoading: false,
  isADPlatformMediaSuccess: false,

  ad_types: null,
  isADTypeLoading: false,
  isADTypeSuccess: false,

  coupangs: null,
  isCoupangLoading: false,
  isCoupangSuccess: false,

  advertisers: null,
  isAdvertiserLoading: false,
  isAdvertiserSuccess: false,

  advertiserAgencies: null,
  isAdvertiserAgenciesLoading: false,
  isAdvertiserAgenciesSuccess: false,

  advertising: null,
  isAdvertisingLoading: false,
  isAdvertisingSuccess: false,

  coupangNasmedias: null,
  isCoupangNasmediaLoading: false,
  isCoupangNasmediaSuccess: false,

  error: {},
};

const ReportSlice = createSlice({
  name: "Report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //report medias
    builder.addCase(reportMedias.pending, (state: IState, action: any) => {
      state.isMediaLoading = true
    });
    builder.addCase(reportMedias.fulfilled, (state: IState, action: any) => {
      state.medias = action.payload.data;
      state.isMediaSuccess = true;
      state.isMediaLoading = false;
    });
    builder.addCase(reportMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSuccess = false;
      state.isMediaLoading = false;
    });

    //report medias v2
    builder.addCase(reportMediasV2.pending, (state: IState, action: any) => {
      state.isMediaV2Loading = true
    });
    builder.addCase(reportMediasV2.fulfilled, (state: IState, action: any) => {
      state.mediasV2 = action.payload.data;
      state.isMediaV2Success = true;
      state.isMediaV2Loading = false;
    });
    builder.addCase(reportMediasV2.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaV2Success = false;
      state.isMediaV2Loading = false;
    });

    //report medias sales
    builder.addCase(reportMediasSales.pending, (state: IState, action: any) => {
      state.isMediaSalesLoading = true
    });
    builder.addCase(reportMediasSales.fulfilled, (state: IState, action: any) => {
      state.mediasSales = action.payload.data;
      state.isMediaSalesSuccess = true;
      state.isMediaSalesLoading = false;
    });
    builder.addCase(reportMediasSales.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSalesSuccess = false;
      state.isMediaSalesLoading = false;
    });

    //report Inventories
    builder.addCase(reportInventories.pending, (state: IState, action: any) => {
      state.isInventoryLoading = true
    });
    builder.addCase(reportInventories.fulfilled, (state: IState, action: any) => {
      state.inventories = action.payload.data;
      state.isInventorySuccess = true;
      state.isInventoryLoading = false;
    });
    builder.addCase(reportInventories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isInventorySuccess = false;
      state.isInventoryLoading = false;
    });

    //report Agencies
    builder.addCase(reportAgencies.pending, (state: IState, action: any) => {
      state.isAgencyLoading = true
    });
    builder.addCase(reportAgencies.fulfilled, (state: IState, action: any) => {
      state.agencies = action.payload.data;
      state.isAgencySuccess = true;
      state.isAgencyLoading = false;
    });
    builder.addCase(reportAgencies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAgencySuccess = false;
      state.isAgencyLoading = false;
    });

    //report Revenues
    builder.addCase(reportRevenues.pending, (state: IState, action: any) => {
      state.isRevenueLoading = true
    });
    builder.addCase(reportRevenues.fulfilled, (state: IState, action: any) => {
      state.revenues = action.payload.data;
      state.isRevenueSuccess = true;
      state.isRevenueLoading = false;
    });
    builder.addCase(reportRevenues.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRevenueSuccess = false;
      state.isRevenueLoading = false;
    });

    //report Commissions
    builder.addCase(reportCommissions.pending, (state: IState, action: any) => {
      state.isCommissionLoading = true
    });
    builder.addCase(reportCommissions.fulfilled, (state: IState, action: any) => {
      state.commissions = action.payload.data;
      state.isCommissionSuccess = true;
      state.isCommissionLoading = false;
    });
    builder.addCase(reportCommissions.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCommissionSuccess = false;
      state.isCommissionLoading = false;
    });

    //report Settlements
    builder.addCase(reportSettlements.pending, (state: IState, action: any) => {
      state.isSettlementLoading = true
    });
    builder.addCase(reportSettlements.fulfilled, (state: IState, action: any) => {
      state.settlements = action.payload.data;
      state.isSettlementSuccess = true;
      state.isSettlementLoading = false;
    });
    builder.addCase(reportSettlements.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSettlementSuccess = false;
      state.isSettlementLoading = false;
    });

    //report Daily
    builder.addCase(reportDaily.pending, (state: IState, action: any) => {
      state.isDailyLoading = true
    });
    builder.addCase(reportDaily.fulfilled, (state: IState, action: any) => {
      state.daily = action.payload.data;
      state.isDailySuccess = true;
      state.isDailyLoading = false;
    });
    builder.addCase(reportDaily.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isDailySuccess = false;
      state.isDailyLoading = false;
    });

    //report Hourly
    builder.addCase(reportHourly.pending, (state: IState, action: any) => {
      state.isHourlyLoading = true
    });
    builder.addCase(reportHourly.fulfilled, (state: IState, action: any) => {
      state.hourly = action.payload.data;
      state.isHourlySuccess = true;
      state.isHourlyLoading = false;
    });
    builder.addCase(reportHourly.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isHourlySuccess = false;
      state.isHourlyLoading = false;
    });

    //report Media Hourly
    builder.addCase(reportMediaHourly.pending, (state: IState, action: any) => {
      state.isMediaHourlyLoading = true
    });
    builder.addCase(reportMediaHourly.fulfilled, (state: IState, action: any) => {
      state.mediaHourly = action.payload.data;
      state.isMediaHourlySuccess = true;
      state.isMediaHourlyLoading = false;
    });
    builder.addCase(reportMediaHourly.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaHourlySuccess = false;
      state.isMediaHourlyLoading = false;
    });

    //report click
    builder.addCase(reportClick.pending, (state: IState, action: any) => {
      state.isClickLoading = true
    });
    builder.addCase(reportClick.fulfilled, (state: IState, action: any) => {
      state.click = action.payload.data;
      state.isClickSuccess = true;
      state.isClickLoading = false;
    });
    builder.addCase(reportClick.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isClickSuccess = false;
      state.isClickLoading = false;
    });

    //report AD Platform
    builder.addCase(reportADPlatforms.pending, (state: IState, action: any) => {
      state.isADPlatformLoading = true
    });
    builder.addCase(reportADPlatforms.fulfilled, (state: IState, action: any) => {
      state.ad_platforms = action.payload.data;
      state.isADPlatformSuccess = true;
      state.isADPlatformLoading = false;
    });
    builder.addCase(reportADPlatforms.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isADPlatformSuccess = false;
      state.isADPlatformLoading = false;
    });


    //report AD Platform Media
    builder.addCase(reportADPlatformMedias.pending, (state: IState, action: any) => {
      state.isADPlatformMediaLoading = true
    });
    builder.addCase(reportADPlatformMedias.fulfilled, (state: IState, action: any) => {
      state.ad_platform_medias = action.payload.data;
      state.isADPlatformMediaSuccess = true;
      state.isADPlatformMediaLoading = false;
    });
    builder.addCase(reportADPlatformMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isADPlatformMediaSuccess = false;
      state.isADPlatformMediaLoading = false;
    });


    //report AD Type
    builder.addCase(reportADTypes.pending, (state: IState, action: any) => {
      state.isADTypeLoading = true
    });
    builder.addCase(reportADTypes.fulfilled, (state: IState, action: any) => {
      state.ad_types = action.payload.data;
      state.isADTypeSuccess = true;
      state.isADTypeLoading = false;
    });
    builder.addCase(reportADTypes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isADTypeSuccess = false;
      state.isADTypeLoading = false;
    });


    //report Coupangs
    builder.addCase(reportCoupangs.pending, (state: IState, action: any) => {
      state.isCoupangLoading = true
    });
    builder.addCase(reportCoupangs.fulfilled, (state: IState, action: any) => {
      state.coupangs = action.payload.data;
      state.isCoupangSuccess = true;
      state.isCoupangLoading = false;
    });
    builder.addCase(reportCoupangs.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCoupangSuccess = false;
      state.isCoupangLoading = false;
    });

    //report Advertiser
    builder.addCase(reportAdvertisers.pending, (state: IState, action: any) => {
      state.isAdvertiserLoading = true
    });
    builder.addCase(reportAdvertisers.fulfilled, (state: IState, action: any) => {
      state.advertisers = action.payload.data;
      state.isAdvertiserSuccess = true;
      state.isAdvertiserLoading = false;
    });
    builder.addCase(reportAdvertisers.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdvertiserSuccess = false;
      state.isAdvertiserLoading = false;
    });


    //report Advertiser Agency
    builder.addCase(reportAdvertiserAgencies.pending, (state: IState, action: any) => {
      state.isAdvertiserAgenciesLoading = true
    });
    builder.addCase(reportAdvertiserAgencies.fulfilled, (state: IState, action: any) => {
      state.advertiserAgencies = action.payload.data;
      state.isAdvertiserAgenciesSuccess = true;
      state.isAdvertiserAgenciesLoading = false;
    });
    builder.addCase(reportAdvertiserAgencies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdvertiserAgenciesSuccess = false;
      state.isAdvertiserAgenciesLoading = false;
    });

    //report Advertising
    builder.addCase(reportAdvertising.pending, (state: IState, action: any) => {
      state.isAdvertisingLoading = true
    });
    builder.addCase(reportAdvertising.fulfilled, (state: IState, action: any) => {
      state.advertising = action.payload.data;
      state.isAdvertisingSuccess = true;
      state.isAdvertisingLoading = false;
    });
    builder.addCase(reportAdvertising.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdvertisingSuccess = false;
      state.isAdvertisingLoading = false;
    });

    //report Coupangs Nasmedia
    builder.addCase(reportCoupangNasmedias.pending, (state: IState, action: any) => {
      state.isCoupangNasmediaLoading = true
    });
    builder.addCase(reportCoupangNasmedias.fulfilled, (state: IState, action: any) => {
      state.coupangNasmedias = action.payload.data;
      state.isCoupangNasmediaSuccess = true;
      state.isCoupangNasmediaLoading = false;
    });
    builder.addCase(reportCoupangNasmedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCoupangNasmediaSuccess = false;
      state.isCoupangNasmediaLoading = false;
    });

  },
});

export default ReportSlice.reducer;