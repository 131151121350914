import { IAdvertiserAgency } from "api/types/_advertiserAgency";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import ModalConfirm from "components/Common/ModalConfirm";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_ADVERTISER_AGENCY_OPTIONS } from "helpers/constans";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { getAdvertiserAgency, getGenerateApiKeyAdvertiserAgency, postAdvertiserAgency, putAdvertiserAgency } from "store/thunks";

import * as Yup from "yup";


export interface Props {
    detail?: IAdvertiserAgency | null;
    triggerRefresh?: (isClose?: boolean) => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormAdvertiserAgency = ({
    detail,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { userPermissions } = useRole();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);

    const [keyShow, setKeyShow] = useState<boolean>(false);


    const [idConfirmRefreshKey, setIdConfirmRefreshKey] = useState<number | null>(null);
    const [isConfirmRefreshKeyLoading, setIsConfirmRefreshKeyLoading] = useState<boolean>(false);

    const STATUS_ADVERTISER_AGENCY_OPTIONS_LANG = STATUS_ADVERTISER_AGENCY_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name,
                website_url: values?.website_url,
                contact_name: values?.contact_name,
                contact_phone: values?.contact_phone,
                contact_email: values?.contact_email,
                ...(!detail?.id ? ({
                    email: values?.email,
                    password: values?.password
                }) : {}),
                status: Number(values?.status ? values?.status?.value : STATUS_ADVERTISER_AGENCY_OPTIONS_LANG[0]?.value), // 0 | 1,
            };
            const response: any = detail?.id ? await putAdvertiserAgency(detail?.id, data) : await postAdvertiserAgency(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("This is required")}`),
        website_url: Yup.string().required(`${t("This is required")}`),
        contact_name: Yup.string().nullable(),
        contact_phone: Yup.string().matches(/^\d+$/, `${t('Phone number should only contain digits')}`).nullable(),
        contact_email: Yup.string().email(`${t('Please enter a valid email address')}`).nullable(),
        status: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),

        email: !!detail?.id ? Yup.string().nullable() : Yup.string().email(`${t('Please enter a valid email address')}`).required(`${t('This is required')}`),
        password: !!detail?.id ? Yup.string().nullable() : Yup.string().required(`${t('This is required')}`),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            website_url: '',
            contact_name: '',
            contact_phone: '',
            contact_email: '',
            email: '',
            password: '',
            api_key: '',
            api_secret: '',
            status: null,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        const v_name = valueDefault?.name;
        const v_website_url = valueDefault?.website_url;
        const v_contact_name = valueDefault?.contact_name;
        const v_contact_phone = valueDefault?.contact_phone;
        const v_contact_email = valueDefault?.contact_email;
        const v_email = valueDefault?.email || '';
        const v_api_key = valueDefault?.api_key || '';
        const v_api_secret = valueDefault?.api_secret || '';
        const v_status = STATUS_ADVERTISER_AGENCY_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.status)
        );

        formik.setFieldValue("name", v_name);
        formik.setFieldValue("website_url", v_website_url);
        formik.setFieldValue("contact_name", v_contact_name);
        formik.setFieldValue("contact_phone", v_contact_phone);
        formik.setFieldValue("contact_email", v_contact_email);
        formik.setFieldValue("email", v_email);
        formik.setFieldValue("api_key", v_api_key);
        formik.setFieldValue("api_secret", v_api_secret);
        formik.setFieldValue("status", v_status);
    };


    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            const getDetail = async () => {
                try {
                    const response: any = await getAdvertiserAgency(detail?.id);
                    if (response?.code === 200) {
                        handleSetValueForm(response?.data || detail);
                    } else {
                        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
                    }
                } catch (error: any) {
                    toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
                    return error;
                }
            }
            getDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    const onCloseConfirmRefreshKeyClick = () => {
        setIdConfirmRefreshKey((_prev) => null);
    };

    const handleActionRefreshKey = async () => {
        if (!idConfirmRefreshKey) {
            return;
        }
        try {
            setIsConfirmRefreshKeyLoading((_prev) => true);
            const response: any = await getGenerateApiKeyAdvertiserAgency(idConfirmRefreshKey);
            if (response?.data) {
                setIsConfirmRefreshKeyLoading((_prev) => false);
                setIdConfirmRefreshKey((_prev) => null);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                formik.setFieldValue("api_key", response?.data?.api_key);
                formik.setFieldValue("api_secret", response?.data?.api_secret);
                triggerRefresh && triggerRefresh(false);
            } else {
                setIsConfirmRefreshKeyLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmRefreshKeyLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand("copy", true, text);
        }
        toast(`${t("Copied Successfully")}`, CONFIG_OPTION_TOAST_NORMAL);
    };

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <div className="mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {/* <Col md={12} lg={12} >
                                                <div>
                                                    <label className="form-label">
                                                        {t("Status")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_ADVERTISER_AGENCY_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded mt-1"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={
                                                            formik?.values?.status ||
                                                            STATUS_ADVERTISER_AGENCY_OPTIONS_LANG[0]
                                                        }
                                                        onChangeSelect={(event: any) =>
                                                            formik.setFieldValue("status", event)
                                                        }
                                                        colors={["danger", "success"]}
                                                    />
                                                    {formik.touched.status && formik.errors.status ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.status}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col> */}
                                            <Col md={12} lg={12}>
                                                <div>
                                                    <label className="form-label mb-2">
                                                        {t("Agency Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        className="mt-1"
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Agency Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Website")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="website_url"
                                                        name="website_url"
                                                        autocomplete={false}
                                                        value={formik?.values?.website_url}
                                                        placeholder={`${t("Website")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("website_url", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.website_url && formik.errors.website_url ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.website_url}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {!!detail?.is_preview && (<>
                                                <Col md={12}>
                                                    <label className="form-label">
                                                        {t("Advertiser Agency API Key")}
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="position-relative w-100 ">
                                                            <Input
                                                                className="form-control"
                                                                id="api_key"
                                                                name="api_key"
                                                                disabled={true}
                                                                type="text"
                                                                value={formik?.values?.api_key}
                                                                placeholder={`${t("Advertiser Agency API Key")}...`}
                                                            />
                                                        </div>
                                                        <button
                                                            className="btn btn-sm btn-soft-success ms-2 fs-14"
                                                            onClick={() =>
                                                                copyTextToClipboard(formik?.values?.api_key || '')
                                                            }
                                                            type="button"
                                                            style={{ borderRadius: 'var(--vz-border-radius)' }}
                                                        >
                                                            <i className="ri-file-copy-line align-middle"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <label className="form-label">
                                                        {t("Advertiser Agency API Secret")}
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="position-relative auth-pass-inputgroup w-100 ">
                                                            <Input
                                                                className="form-control"
                                                                id="api_secret"
                                                                name="api_secret"
                                                                disabled={true}
                                                                type={keyShow ? "text" : "password"}
                                                                value={formik?.values?.api_secret}
                                                                placeholder={`${t("Advertiser Agency API Secret")}...`}
                                                            />
                                                            <button
                                                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                                                onClick={() => setKeyShow(!keyShow)}
                                                                type="button"
                                                                id="password-addon"

                                                            >
                                                                <i className="ri-eye-fill align-middle"></i>
                                                            </button>
                                                        </div>

                                                        {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_AGENCY_REFRESH_KEY, userPermissions) && <button
                                                            className="btn btn-sm btn-soft-primary ms-2 fs-14"
                                                            onClick={() =>
                                                                setIdConfirmRefreshKey((prev) => Number(detail?.id))
                                                            }
                                                            type="button"
                                                            style={{ borderRadius: 'var(--vz-border-radius)' }}
                                                        >
                                                            <i className="ri-refresh-line align-middle"></i>
                                                        </button>}
                                                        <button
                                                            className="btn btn-sm btn-soft-success ms-2 fs-14"
                                                            onClick={() =>
                                                                copyTextToClipboard(formik?.values?.api_secret || '')
                                                            }
                                                            type="button"
                                                            style={{ borderRadius: 'var(--vz-border-radius)' }}
                                                        >
                                                            <i className="ri-file-copy-line align-middle"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            </>)}
                                            {!detail?.id && (
                                                <div className="position-relative" style={{ border: '1px dashed var(--vz-primary)', borderRadius: '5px' }}>
                                                    <label className="position-absolute fs-12 text-primary mb-0 px-0" style={{ backgroundColor: "var(--vz-light)", top: '0px', transform: 'translateY(-50%)' }} >
                                                        {t('Button Update Account Login Advertiser Agency')}
                                                    </label>
                                                    <Col lg={12} className="mb-3 pt-3">
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Email")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                id="email"
                                                                name="email"
                                                                autocomplete={false}
                                                                value={formik?.values?.email}
                                                                placeholder={`${t("Email")}...`}
                                                                onChange={(event: any) => formik.setFieldValue("email", event?.target?.value || "")}
                                                            />
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.email}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div>
                                                            <label className="form-label">
                                                                {t("Password")}
                                                                <span className="text-danger"> *</span>
                                                            </label>
                                                            <div className="position-relative auth-pass-inputgroup mb-2">
                                                                <Input
                                                                    id="password"
                                                                    name="password"
                                                                    value={formik?.values?.password}
                                                                    type={passwordShow ? "text" : "password"}
                                                                    className="form-control pe-5"
                                                                    autocomplete={false}
                                                                    placeholder={`${t("Password")}...`}
                                                                    onChange={(event: any) => formik.setFieldValue("password", event?.target?.value || "")}
                                                                />
                                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={() => setPasswordShow(!passwordShow)} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                            </div>
                                                            {formik.touched.password && formik.errors.password ? (
                                                                <div className="text-danger mt-2">
                                                                    {formik.errors.password}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </div>)}
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Name")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_name"
                                                        name="contact_name"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_name}
                                                        placeholder={`${t("Contact Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_name && formik.errors.contact_name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Phone")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_phone"
                                                        name="contact_phone"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_phone}
                                                        placeholder={`${t("Contact Phone")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_phone", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_phone && formik.errors.contact_phone ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_phone}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Email")}
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_email"
                                                        name="contact_email"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_email}
                                                        placeholder={`${t("Contact Email")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_email", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_email && formik.errors.contact_email ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Close")}
                                                    </button>

                                                    {!detail?.is_preview && (<button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '210px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Agency") : t("Button Create Agency")}</>}
                                                    </button>)}
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
            <ModalConfirm
                textButtonConfirm={t("Button Refresh Key Agency")}
                classButtonConfirm="btn-soft-primary"
                classIconButtonConfirm="ri-refresh-line"
                header={t("Button Refresh Key Agency")}
                title={t("Do you want to refresh key of agency!")}
                content={t(
                    "After confirming the refresh action, the data will be updated and cannot be recovered. Do you want to proceed with the refresh action."
                )}
                isShowIcon={false}
                isOpen={!!idConfirmRefreshKey}
                isLoading={isConfirmRefreshKeyLoading}
                onClose={onCloseConfirmRefreshKeyClick}
                onConfirm={handleActionRefreshKey}
            />
        </React.Fragment >
    );
};

export default FormAdvertiserAgency;
